<template>
  <!-- container -->
  <div id="container">
    <!-- 본문 -->
    <div id="contents" class="service-con">
      <div class="service">
      <!-- 서브 배너 -->
      <section class="subTopArea inner-fixed">
        <h2 v-html="$t('views.service.title')"></h2>
        <small v-html="$t('views.service.titles')"></small>
        <div class="btn-cen">
          <a href="/Jonathan Brochure.pdf" target="_blank" class="button button-down"><div v-html="$t('views.service.brochure')"></div></a>
        </div>
      </section>
      <!-- //서브 배너 -->
      <section id="main-jonathan">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title"><img id="logo-main-jonathan" src="@/assets/images/logo-jonathan.png" alt="jonathan"></h2>
          </div>
          <small v-html="$t('views.service.content01')"></small>
          <a :href="`https://jonathan.acryl.ai?lang=${$_gGet_Locale()}`" class="button-go-white" target="_blank">{{ $t('views.service.content02') }}</a>
        </div>
      </section>
      <section class="serviceWrap">
        <div class="areaBox inner-fixed">
          <h3 v-html="$t('views.service.content03')"></h3>
          <ul class="serviceBox">
            <li @click="openTo('https://jonathan.acryl.ai/intelligence')">
              <img src="@/assets/images/icon-intelligence.svg" alt="" class="t-none service-ico">
              <!-- <img src="@/assets/images/icon-jonathan-intelligence-m.svg" alt="" class="pc-none t-block"> -->
              <img src="@/assets/images/icon-intelligence-m.svg" alt="" class="pc-none t-block">
              <p class="textInfo">
                {{ $t('views.service.content04') }}<br>
                <span class="m-none"><div v-html="$t('views.service.content05')"></div></span>
                <a @click="openTo('https://jonathan.acryl.ai/intelligence')"><span>{{ $t('views.service.content06') }}</span> <i class="icon-arrow">&gt;</i></a>
              </p>
            </li>
            <li @click="openTo('https://jonathan.acryl.ai/bots')">                        
              <img src="@/assets/images/icon-chatbot.svg" alt="" class="t-none service-ico">
              <!-- <img src="@/assets/images/icon-jonathan-bots.svg" alt="" class="t-none"> -->
              <!-- <img src="@/assets/images/icon-jonathan-bots-m.svg" alt="" class="pc-none t-block"> -->
              <img src="@/assets/images/icon-chatbot-m.svg" alt="" class="pc-none t-block">
              <p class="textInfo">
                {{ $t('views.service.content07') }}<br>
                <span class="m-none"><div v-html="$t('views.service.content08')"></div><div v-html="$t('views.service.content09')"></div></span>
                <a @click="openTo('https://jonathan.acryl.ai/bots')"><span>{{ $t('views.service.content06') }}</span> <i class="icon-arrow">&gt;</i></a>
              </p>
            </li>
            <li @click="openTo('https://jonathan.acryl.ai/flightbase')">
              <img src="@/assets/images/icon-flightbase.svg" alt="" class="t-none service-ico">
              <!-- <img src="@/assets/images/icon-jonathan-flightbase.svg" alt="" class="t-none"> -->
              <!-- <img src="@/assets/images/icon-jonathan-flightbase-m.svg" alt="" class="pc-none t-block"> -->
              <img src="@/assets/images/icon-flightbase-m.svg" alt="" class="pc-none t-block">
              <p class="textInfo">
                {{ $t('views.service.content10') }}<br>
                <span class="m-none"><div v-html="$t('views.service.content11')"></div></span>
                <a @click="openTo('https://jonathan.acryl.ai/flightbase')"><span>{{ $t('views.service.content06') }}</span> <i class="icon-arrow">&gt;</i></a>
              </p>
            </li>
            <li @click="openTo('https://jonathan.acryl.ai/marker')">
              <img src="@/assets/images/icon-jonathan-marker.svg" alt="" class="t-none service-ico">
              <!-- <img src="@/assets/images/icon-jonathan-marker.svg" alt="" class="t-none"> -->
              <!-- <img src="@/assets/images/icon-jonathan-marker-m.svg" alt="" class="pc-none t-block"> -->
              <img src="@/assets/images/icon-jonathan-marker-m.svg" alt="" class="pc-none t-block">
              <p class="textInfo">
                {{ $t('views.service.content12') }} <br>
                <span class="m-none"><div v-html="$t('views.service.content13')"></div></span>
                <a @click="openTo('https://jonathan.acryl.ai/marker')"><span>{{ $t('views.service.content06') }}</span> <i class="icon-arrow">&gt;</i></a>
              </p>
            </li>
            <li @click="openTo('https://jonathan.acryl.ai/datascope')">
              <img src="@/assets/images/icon-datascope.svg" alt="" class="t-none service-ico">
              <!-- <img src="@/assets/images/icon-jonathan-datascope.svg" alt="" class="t-none"> -->
              <!-- <img src="@/assets/images/icon-jonathan-datascope-m.svg" alt="" class="pc-none t-block"> -->
              <img src="@/assets/images/icon-datascope-m.svg" alt="" class="pc-none t-block">
              <p class="textInfo">
                {{ $t('views.service.content14') }} <br>
                <span class="m-none"><div v-html="$t('views.service.content15')"></div></span>
                <a @click="openTo('https://jonathan.acryl.ai/datascope')"><span>{{ $t('views.service.content06') }}</span> <i class="icon-arrow">&gt;</i></a>
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section class="hugbotWrap">
        <div class="areaBox inner-fixed">
          <h3 v-html="$t('views.service.content16')"></h3>
          <small v-html="$t('views.service.content17')"></small>
          <div :class="`${$_gGet_Locale()} diagram`">
            <img src="@/assets/images/image-hug-bot.svg" alt="">
            <span class="txt1">{{ $t('views.service.content18') }}</span>
            <span class="txt2">{{ $t('views.service.content19') }}</span>
          </div>
        </div>
      </section>
      <section :class="`${$_gGet_Locale()} hugbotBn`">
        <div class="areaBox inner-fixed">
          <h3 class="title" v-html="$t('views.service.content20')"></h3>
          <small v-html="$t('views.service.content21')"></small>
          <a @click="openTo('http://hugbot.acryl.ai')" class="button-go-white middle">{{ $t('views.service.content22') }}</a>
        </div>
      </section>
      </div>
    </div>
    <!-- 본문 -->
  </div>
  <!-- //container -->
</template>

<script>
import { openTo } from '@/assets/js/util.js'

export default {
  methods: {
    openTo (path) { openTo(path, true) },
  },
}
</script>